<template>
  <div>

<!-- <iframe class="iFrame" src="https://www.thedjsvault.com/mentor"></iframe> -->

  </div>
</template>

<script>
export default {

}
</script>

 <style>

</style>